const data = [
    {
        id: 1,
        title: "EHR solution",
        image: "img/project/project-1.jpg",
        // category: "developing",
        details: "Custom EHR (Electronic Health Records) System",
        link: `${process.env.PUBLIC_URL}/Custom`
    },
    {
        id: 2,
        title: "Solutions Platform",
        image: "img/project/project-2.jpg",
        // category: "web",
        details: "Banking Solutions Platform",
        link: `${process.env.PUBLIC_URL}/Banking-Solutions-Platform`
    },
    {
        id: 3,
        title: "Management Software",
        image: "img/project/project-3.jpg",
        category: "App",
        details: "Employee Management Software",
        link: `${process.env.PUBLIC_URL}/Employee-Management-Software`
    },
    {
        id: 4,
        title: "Billing and Claims Management System",
        image: "img/project/project-4.jpg",
        // category: "UI",
        details: "Healthcare Billing and Claims Management System",
        link: `${process.env.PUBLIC_URL}/Healthcare-Billing-and-Claims-Management-System`
    },
    {
        id: 5,
        title: "Inventory Management Systems",
        image: "img/project/project-5.jpg",
        // category: "Graphic",
        details: "Cloud-Based Inventory Management System",
        link: `${process.env.PUBLIC_URL}/Cloud-Based-Inventory-Management-System`
    },
    {
        id: 6,
        title: "Custom CRM for Sales Teams",
        image: "img/project/project-6.jpg",
        // category: "3D Design",
        details: "Custom CRM for Sales Teams",
        link: `${process.env.PUBLIC_URL}/Custom-CRM-for-Sales-Teams`
    },
   
    {
        id: 7,
        title: "Insurance Policy Management",
        image: "img/project/project-4.jpg",
        // category: "UI",
        details: "Insurance Policy Management Platform",
        link: `${process.env.PUBLIC_URL}/Insurance-Policy-Management-Platform`
    },
    {
        id: 8,
        title: "E-Commerce-Platform-Development",
        image: "img/project/project-1.jpg",
        // category: "developing",
        details: "E-Commerce Platform Development",
        link: `${`${process.env.PUBLIC_URL}/E-Commerce-Platform-Development`}`
    },
    {
        id: 9,
        title: "Telemedicine Platform",
        image: "img/project/project-5.jpg",
        // category: "Graphic",
        details: "Telemedicine Platform for Healthcare Providers",
        link: `${process.env.PUBLIC_URL}/Telemedicine-Platform-for-Healthcare-Providers`
    },
];

export default data;
