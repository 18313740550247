import React from 'react'
import { Link } from 'react-router-dom'
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";
SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

function ProjectDetailsContent() {
    const projectSlider ={
        loop: true,
        slidesPerView: 1,
        navigation: {
            nextEl: ".swiper-button-next-c",
            prevEl: ".swiper-button-prev-c",
        } 
    }
  return (
    <>
     <div className="project-details-content">
        <div className="project-thumb">
          <img src={process.env.PUBLIC_URL + '/img/project/thumbnail-project.jpg'} alt="mages" />
          {/* <div className="tag">
            <Link to={`${process.env.PUBLIC_URL}/project-details`} onClick={window.scrollTo({top:0,behavior:"smooth"})}>Software</Link>
          </div> */}
        </div>
        <h3>Banking Solutions Platform</h3>
        <p>The Banking Solutions Platform is a cutting-edge, secure, and scalable system designed to revolutionize banking operations. This platform enhances transaction management, improves fraud detection capabilities, and boosts customer engagement by integrating advanced technologies and user-friendly interfaces. Built with state-of-the-art security protocols, it ensures that both customer data and transactions are protected against evolving threats, making it a robust and reliable solution for modern banking needs.</p>
        <div className="clinet-need">
          <h4>Key Features</h4>
          <h5>1.Enhanced Transaction Management</h5>
          <p>The platform streamlines transaction processes, allowing for faster and more efficient handling of payments, transfers, and account activities. Its real-time processing capabilities ensure that banking institutions can manage high volumes of transactions with ease, significantly improving operational efficiency.</p>
          <h5>2.Advanced Fraud Detection in Banking:</h5>
          <p>Using machine learning algorithms and AI-driven analytics, the platform proactively identifies and mitigates fraudulent activities. By analyzing transaction patterns and customer behavior, the system detects anomalies in real-time, reducing the risk of fraud and ensuring a secure banking environment.</p>
          <h5>3.Customer Engagement in Banking:</h5>
          <p>With its intuitive interface, the platform provides an enhanced user experience, making it easier for customers to interact with their accounts and banking services. Personalized dashboards, notifications, and seamless navigation ensure that customers are more engaged and satisfied with their banking experience.</p>
          <br/>
          <h5>Security and Scalability</h5>
          <p>The platform employs advanced security protocols, including end-to-end encryption, multi-factor authentication, and real-time monitoring, to safeguard both user data and financial transactions. Its scalable infrastructure allows banking institutions to handle growing transaction volumes without compromising performance or security, making it an ideal solution for both small banks and large financial institutions.</p>
          <br/>
          <h5>Technologies Used
          </h5>
          <li>Machine Learning and AI for fraud detection and customer analytics</li>
          <li>Cloud Computing to ensure scalability and availability</li>
          <li>Blockchain for secure transaction management</li>
          <li>Data Encryption and Authentication for enhanced security
          </li>
        </div>
        {/* <div className="working-process">
          <h4>Working Process</h4>
          <Swiper {...projectSlider} className="swiper work-process-slide">
            <div className="swiper-wrapper">
              <SwiperSlide className="swiper-slide">
                <div className="work-thumb">
                  <img src={process.env.PUBLIC_URL + "/img/project/work-thumb-slide.jpg"} alt="mages" />
                  <div className="label">
                    <span>01. Brainstorming</span>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="work-thumb">
                  <img src={process.env.PUBLIC_URL + '/img/project/work-thumb-slide-1.jpg'} alt="mages" />
                  <div className="label">
                    <span>02. Drawing</span>
                  </div>
                </div>
              </SwiperSlide>
            </div>
            <div className="arrow">
              <div className="swiper-button-next-c"><i className="bi bi-arrow-right" /></div>
              <div className="swiper-button-prev-c"><i className="bi bi-arrow-left" /></div>
            </div>
          </Swiper>
          <p>Interdum et malesuada fames ac ante ipsum primis in faucibus. Etiam eu nibh elementum, accumsan ona neque ac, aliquet nunc. In eu ipsum fringilla, accumsan purus vel, pellentesque risus. Vivamus vehicula nl purus at eros interdum, in dignissim nulla vestibulum. Nunc sit amet finibus felis, ut egestas lacus. Sedan pellentesque quis magna eu vestibulum. Ut sed commodo neque. Morbi erat nis</p>
        </div> */}
        {/* <div className="check-lunch">
          <h4>Check &amp; Launch</h4>
          <div className="row">
            <div className="col-lg-6">
              <ul className="check">
                <li><i><img src={process.env.PUBLIC_URL + '/img/icons/check.svg'} alt="mages" /></i>Aenean vitae pharetra leo. Aliquam pri.</li>
                <li><i><img src={process.env.PUBLIC_URL + '/img/icons/check.svg'} alt="mages" /></i>In aliquet, quam vitae blandit temporg.</li>
                <li><i><img src={process.env.PUBLIC_URL + '/img/icons/check.svg'} alt="mages" /></i>Aenean volutpat enim vitae tincidunta.</li>
                <li><i><img src={process.env.PUBLIC_URL + '/img/icons/check.svg'} alt="mages" /></i>Felis at venenatis porttitor, nunc arcua.</li>
                <li><i><img src={process.env.PUBLIC_URL + '/img/icons/check.svg'} alt="mages" /></i>Maximus felis quam vitae tortor phase.</li>
                <li><i><img src={process.env.PUBLIC_URL + '/img/icons/check.svg'} alt="mages" /></i>Nulla vulputate urna quis ex pellentes.</li>
              </ul>
            </div>
            <div className="col-lg-6">
              <p>Interdum et malesuada fames ac ante ipsum tobal primis in faucibus. Etiam eu nibh elementum, tolda accumsan ona In eu ipsum fringilla, accumsan pur neque ac, aliquet nunc. In eu ipsum fringilla, wolan accumsan purus vel, pellentesque risus. Vivamusa
                purus at eros interdum, in dignissim nulla Ut sedal vestibulum. Nunc sit amet finibus felis, ut egestas lacus. Sedan commodo neque. Morbi erat niswole pellentesque quis magna eu vestibulum. </p>
            </div>
          </div>
        </div> */}
      </div>   
    </>
  )
}

export default ProjectDetailsContent