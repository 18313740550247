import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Layout from "./components/App";
import MainLayout from "./components/layout/MainLayout";
import About from "./components/page/about/About";
import BlogSidebar from "./components/page/blog/BlogSidebar";
import BlogDetails from "./components/page/BlogDetails/BlogDetails";
import BlogDetails1 from "./components/page/BlogDetails1/BlogDetails1";
import BlogDetails2 from "./components/page/BlogDetails2/BlogDetails2";
import BlogStandard from "./components/page/BlogStandard/BlogStandard";
import Contact from "./components/page/contact/Contact";
import Contact1 from "./components/page/contact1/Contact1";
import ErrorPage from "./components/page/error/ErrorPage";
import Faq from "./components/page/faq/Faq";
import HomePageTwo from "./components/page/homepage2/HomePageTwo";
import Pricing from "./components/page/pricing/Pricing";
import Project from "./components/page/project/Project";
import ProjectDetails from "./components/page/ProjectDetails/ProjectDetails";
import ProjectDetails1 from "./components/page/ProjectDetails1/ProjectDetails1";
import ProjectDetails2 from "./components/page/ProjectDetails2/ProjectDetails2";
import ProjectDetails3 from "./components/page/ProjectDetails3/ProjectDetails3";
import ProjectDetails4 from "./components/page/ProjectDetails4/ProjectDetails4";
import ProjectDetails5 from "./components/page/ProjectDetails5/ProjectDetails5";
import ProjectDetails6 from "./components/page/ProjectDetails6/ProjectDetails6";
import ProjectDetails7 from "./components/page/ProjectDetails7/ProjectDetails7";
import ProjectDetails8 from "./components/page/ProjectDetails8/ProjectDetails8";
import Service from "./components/page/service/Service";
import ServiceDetails from "./components/page/ServiceDetails/ServiceDetails";
import ServiceDetails1 from "./components/page/ServiceDetails1/ServiceDetails1";
import ServiceDetails2 from "./components/page/ServiceDetails2/ServiceDetails2";
import ServiceDetails3 from "./components/page/ServiceDetails3/ServiceDetails3";
import ServiceDetails4 from "./components/page/ServiceDetails4/ServiceDetails4";
import ServiceDetails5 from "./components/page/ServiceDetails5/ServiceDetails5";
import ServiceDetails6 from "./components/page/ServiceDetails6/ServiceDetails6";
import ServiceDetails7 from "./components/page/ServiceDetails7/ServiceDetails7";

import Team from "./components/page/team/Team";
import "./index.css";

// Default Warning/Error Hide
console.log = console.warn = console.error = () => {};

/*
=>version : 0.1
=>Event : Rendering all content to the web
=>Action: define all routes and page
@return HTML
*/

function Root() {
  return (
    <BrowserRouter basename="/">
      <Switch>
        {/* Redirect root path to index2 */}
        <Redirect exact from="/" to="/index2" />

        {/* Load the second layout as the main page */}
        <Route path="/index2" component={HomePageTwo} />

        {/* All inner pages load layout component */}
        <Layout>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/about`}
            component={About}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/contact`}
            component={Contact}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/error`}
            component={ErrorPage}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog`}
            component={BlogSidebar}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog-standard`}
            component={BlogStandard}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/How-AI-is-Revolutionizing-Healthcare-Enhancing-Patient-Care-and-Operational Efficiency`}
            component={BlogDetails}
          />
           <Route
            exact
            path={`${process.env.PUBLIC_URL}/AI-in-Banking-Driving-Customer-Experience-and-Fraud-Prevention-with-Advanced-Algorithms`}
            component={BlogDetails1}
          />
           <Route
            exact
            path={`${process.env.PUBLIC_URL}/The-Future-of-Insurance-with-AI-Transforming-Claims-Processing-and-Risk-Assessment`}
            component={BlogDetails2}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/service`}
            component={Service}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/IT-Support-for-Businesses`}
            component={ServiceDetails}
          />
           <Route
            exact
            path={`${process.env.PUBLIC_URL}/Network-Monitoring-Management`}
            component={ServiceDetails1}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Cloud Services & Cloud Management`}
            component={ServiceDetails2}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Cybersecurity-Solutions`}
            component={ServiceDetails3}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Data-Backup-Disaster-Recovery`}
            component={ServiceDetails4}
          />
           <Route
            exact
            path={`${process.env.PUBLIC_URL}/Enterprise-Software-Development`}
            component={ServiceDetails5}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Remote-Monitoring-Maintenance`}
            component={ServiceDetails6}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/IT-Consulting-&-Strategy`}
            component={ServiceDetails7}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/IT-Consulting-&-Strategy`}
            component={ServiceDetails7}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Custom`}
            component={ProjectDetails}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Banking-Solutions-Platform`}
            component={ProjectDetails1}
          />
           <Route
            exact
            path={`${process.env.PUBLIC_URL}/Employee-Management-Software`}
            component={ProjectDetails2}
          />
           <Route
            exact
            path={`${process.env.PUBLIC_URL}/Healthcare-Billing-and-Claims-Management-System`}
            component={ProjectDetails3}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Cloud-Based-Inventory-Management-System`}
            component={ProjectDetails4}
          />
           <Route
            exact
            path={`${process.env.PUBLIC_URL}/Custom-CRM-for-Sales-Teams`}
            component={ProjectDetails5}
          />
           <Route
            exact
            path={`${process.env.PUBLIC_URL}/Insurance-Policy-Management-Platform`}
            component={ProjectDetails6}
          />
            <Route
            exact
            path={`${process.env.PUBLIC_URL}/E-Commerce-Platform-Development`}
            component={ProjectDetails7}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Telemedicine-Platform-for-Healthcare-Providers`}
            component={ProjectDetails8}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/project`}
            component={Project}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/team`}
            component={Team}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/pricing`}
            component={Pricing}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/faq`}
            component={Faq}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Get-a-Quote`}
            component={Contact1}
          />
        </Layout>
      </Switch>
    </BrowserRouter>
  );
}

export default Root;

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById("root")
);
