import React from "react";
import { Link } from "react-router-dom";
import ServiceDetailsItems from "./ServiceDetailsItems";

function ServiceDetailsWrap() {
  const scrollTop =()=>window.scrollTo({top:0,behavior:"smooth"})
  return (
    <>
      <div className="service-details sec-mar-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-details-content">
                <img src={process.env.PUBLIC_URL + '/img/blog/blog-thumnail.jpg'} alt="images service" />
                <h3>
                  <i>
                    <img
                      src={process.env.PUBLIC_URL + '/img/icons/service-details-icon.png'}
                      alt="service icon images"
                    />
                  </i>
                  IT Support for Businesses: Ensuring Seamless Operations and Growth
                </h3>
                <p>
                In today’s fast-paced digital world, businesses of all sizes rely heavily on technology to maintain daily operations, enhance productivity, and drive growth. Reliable IT support for businesses is critical in ensuring that technology remains an asset rather than a hindrance. From preventing costly downtime to ensuring the security of sensitive data, professional IT support plays a pivotal role in keeping your business running smoothly.
                </p>
                <p>
                Whether you’re a small business or a large enterprise, having the right IT infrastructure and support is essential for staying competitive in a rapidly evolving technological landscape. In this content, we’ll explore the importance of IT support, the various types of services available, the benefits of outsourcing, and why investing in IT support is crucial for long-term success.
                </p>
                {/* <p>
                  vestibulum ultricies tellus. volv Pellentesque vel turpis
                  vitae urna tincidunt hendrerit at ut est. Sed eget feugiat
                  felis. Integer sed ornare sem, eget porttitor nisi. Nunc erat
                  sapien, porta laoreet gravida ac,{" "}
                </p> */}
                <h3>
                Types of IT Support Services we Provide
                </h3>
                <p>IT support services come in various forms, depending on the needs of your business. Below are some of the most common types of business IT solutions:</p>
              </div>
            </div>
            {/* <div className="col-lg-4">
              <div className="sidebar-widget">
                <h4>Category</h4>
                <ul className="category">
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/project`} onClick={scrollTop}>
                      Web Design
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/project`} onClick={scrollTop}>
                      Apps Development
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/project`} onClick={scrollTop}>
                      Software Development
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/project`} onClick={scrollTop}>
                      Motion Graphics
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/project`} onClick={scrollTop}>
                      UI/UX Design
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/project`} onClick={scrollTop}>
                      Graphic Design
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="sidebar-banner">
                <img src={process.env.PUBLIC_URL + '/img/widget-banner-bg.jpg'} alt="Banner images" />
                <div className="banner-inner">
                  <h3>
                    Any Project <span>Call Now.</span>
                    <img
                      className="angle"
                      src={process.env.PUBLIC_URL + "/img/arrow-angle.png"}
                      alt=""
                    />
                  </h3>
                  <a href="tel:11231231234">+1-123-123-1234</a>
                </div>
              </div>
            </div> */}
          </div>
          <ServiceDetailsItems />
        </div>
      </div>
    </>
  );
}

export default ServiceDetailsWrap;
