import React, { useState } from 'react'
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";

function AboutArea(props) {
  const [isOpen, setOpen]=useState(false)
  return (
    <>
      <section className="about-area sec-mar-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 or-2 wow animate fadeIn" data-wow-delay="200ms" data-wow-duration="1500ms">
              <div className={`${"sec-title layout2"} ${props.white}`}>
                <span>Get To Know</span>
                <h2>About Us</h2>
              </div>
              <div className={`${"about-left"} ${props.layoutClass}`}>
                <h3>Kenzo Infotech: Driving 360° Innovation in Digital Solutions</h3>
                <p>At Kenzo Infotech, we are dedicated to driving innovation and delivering cutting-edge technology solutions that empower businesses to thrive in today’s fast-paced digital world. With a strong focus on custom enterprise software development, managed IT services, and cloud-based solutions, we tailor our expertise to meet the unique needs of each client.

Founded by a team of industry experts with a passion for solving complex challenges, Kenzo Infotech is built on the principles of innovation, agility, and collaboration. 

Backed by a proven track record in leading successful projects at both corporate giants and startups, our team combines technical excellence with a deep understanding of industry-specific requirements. We pride ourselves on our commitment to customer satisfaction, delivering proactive support, and creating bespoke solutions that accelerate growth.
 </p>
                {/* <div className={`${"company-since"} ${props.layoutClass}`}>
                  <div className="company-logo">
                    <img src={`${process.env.PUBLIC_URL} ${props.image}`} alt="about-images" />
                  </div>
                  <strong>#1</strong>
                  <h4>Best Creative IT Agency And Solutions <span></span></h4>
                </div> */}
              </div>
            </div>
            <div className="col-lg-6 or-1 wow animate fadeIn" data-wow-delay="200ms" data-wow-duration="1500ms">
              <div className="about-right">
                <div className="banner-1">
                  <img src={process.env.PUBLIC_URL + '/img/about-baner-1.jpg'} alt="about-images" />
                </div>
                <div className="banner-2">
                  <img src={process.env.PUBLIC_URL + '/img/about-baner-2.jpg'}  alt="about-images" />
                  <div className="banner2-inner">
                    <div className="play">
                      <div className="video-popup" onClick={()=>setOpen(true)} ><i className="fas fa-play" /></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>  
      <React.Fragment>
        <ModalVideo
          channel="youtube"
          isOpen={isOpen}
          videoId="WOl86x8Ulg8"
          autoplay = {1}
          onClose={() => setOpen(false)}
        />
      </React.Fragment> 
    </>
  )
}

export default AboutArea