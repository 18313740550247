import React from 'react'
import { Link } from 'react-router-dom'

function Hero2() {
    const scrollTop =()=>window.scrollTo({top:0,behavior:"smooth"})
  return (
    <>
     <section className="hero-area">
        <div id="particles-js" />
        <div className="verticale-social">
          <ul className="vertical-media">
            <li><a href="https://www.facebook.com/profile.php?id=61554916179085&mibextid=kFxxJD">Facebook</a></li>
            <li><a href="https://www.instagram.com/kenzo_infotech?igsh=ZmNmOTRmZDcxZWc4&utm_source=qr">Instagram</a></li>
            <li><a href="https://www.linkedin.com/company/kenzo-infotech-usa/">Linkedin</a></li>
          </ul>
        </div>
        <div className="hero-wrapper">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="hero-content layout2">
                  <h1 style={{ fontSize: '34px' }}>Crafting Innovative Software Solutions,<br/><span>Empowering Tomorrow's Technology"
                  </span></h1>
                  <p>Kenzo Infotech, where innovation meets impact. At the core of everything we do is a commitment to creating 360° value for our clients, partners, and stakeholders. With cutting-edge technology solutions and a forward-thinking approach, we drive success in healthcare, digital transformation, and beyond.</p>
                  <div className="buttons">
                    <div className="cmn-btn">
                      <div className="line-1" />
                      <div className="line-2" />
                      <Link to={`${process.env.PUBLIC_URL}/about`} onClick={scrollTop}>About Us</Link>
                    </div>
                    <div className="cmn-btn layout-two">
                      <div className="line-1" />
                      <div className="line-2" />
                      <Link to={`${process.env.PUBLIC_URL}/project`} onClick={scrollTop}>See Project</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="hero-img">
                  <img src={process.env.PUBLIC_URL + '/img/online.svg'} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>   
    </>
  )
}

export default Hero2